import { useEffect, useState } from "react";
import { useUser } from "../auth/userContext";
import styles from "./ManualUpload.module.css";
import { toast } from "react-toastify";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker?worker";
import { endOfDay, format, set, startOfDay } from "date-fns";
import { supabase } from "../supabase/client";

// Register the worker
GlobalWorkerOptions.workerPort = new pdfjsWorker();

function ManualUpload() {
  const { userProfile } = useUser();

  const [userHotels, setUserHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  // Utility functions

  // Helper: Format date from Spanish to English
  const formatDate = (d) => {
    const [day, mon, yr] = d.split("-");
    const monthNum = monthToNumber(mon) + 1; // 0-based to 1-based
    const paddedMonth = String(monthNum).padStart(2, "0");
    const paddedDay = String(day).padStart(2, "0");
    return `20${yr}-${paddedMonth}-${paddedDay}`;
  };

  // Helper: Spanish month abbreviation to zero-indexed month number
  const monthToNumber = (mon) => {
    const map = {
      Ene: 0,
      Feb: 1,
      Mar: 2,
      Abr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Ago: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dic: 11,

      // English fallbacks
      Jan: 0,
      Apr: 3,
      Aug: 7,
      Dec: 11,
    };
    return map[mon] ?? 0;
  };

  const checkDuplicateUpload = async (
    hotelId,
    created_at,
    firstDate,
    lastDate
  ) => {
    // Check if a record for this hotel and date already exists
    // check the count of records for this hotel_id and created_at date, if it is greater than 0, then return true

    const start = new Date(startOfDay(created_at).toISOString());
    const end = new Date(endOfDay(created_at).toISOString());

    const { count, error } = await supabase
      .from("hotel_facts")
      .select("*", { count: "exact" })
      .eq("hotel_id", hotelId)
      .gte("created_at", start.toISOString())
      .lte("created_at", end.toISOString())
      .gte("date", firstDate)
      .lte("date", lastDate);

    if (error) {
      toast.error(`Failed to check for existing record: ${error.message}`);
      return;
    }

    return count > 0;
  };

  // End of utility functions

  const fetchUserHotels = () => {
    // Fetch all active hotels that this user has access to
    if (!userProfile || userProfile.user_hotel.length === 0) return;
    setUserHotels(
      userProfile.user_hotel
        .slice() // Clone the array to avoid mutating the original
        .sort((a, b) => a.hotels.name.localeCompare(b.hotels.name)) // Sort the array by hotel name
        .map((uh) => ({
          id: uh.hotels.id,
          code: uh.hotels.code,
          name: uh.hotels.name,
          active: uh.hotels.active,
        }))
    );
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();

    // Check that hotelId and file are not empty
    if (!selectedHotel || !selectedFile) {
      toast.error("Hotel and file are required.");
      return;
    }

    // Check to ensure the file is a PDF
    if (selectedFile.type !== "application/pdf") {
      toast.error("Invalid file format. Please upload a PDF file.");
      return;
    }

    // Read the file content
    const reader = new FileReader();
    reader.onload = async (e) => {
      const pdfData = new Uint8Array(e.target.result);
      const pdf = await getDocument({ data: pdfData }).promise;

      let fullText = "";
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const text = textContent.items.map((item) => item.str).join(" ");
        fullText += text + "\n";
      }

      // Grab the hotel name from the PDF
      const hotelNameMatch = fullText.match(
        /([A-Z][A-Za-zÁÉÍÓÚÜÑ&\s]+?)\s+-\s+.+?\s+Pagina:\s*\d+\/\d+/
      );

      if (!hotelNameMatch || !hotelNameMatch[1]) {
        toast.error(
          "Unable to extract hotel name from PDF. Please check the file."
        );
        return;
      }

      const hotelNameFromPdf = hotelNameMatch[1].trim();

      if (!hotelNameFromPdf) {
        toast.error(
          "Unable to extract hotel name from PDF. Please check the file."
        );
        return;
      }

      // Check if the hotel name from the PDF matches the selected hotel
      const hotelNameFromDb = userHotels.find(
        (hotel) => hotel.id === selectedHotel
      ).name;

      if (hotelNameFromPdf !== hotelNameFromDb) {
        toast.error(
          `The hotel code in the PDF "${hotelNameFromPdf}" does not match the selected hotel "${hotelNameFromDb}". Please check the file and/or select the appropriate hotel.`
        );
        return;
      }

      // Parse date string safely
      const [_, dd, mon, yy, hh, mm] =
        fullText.match(/(\d{2})-(\w{3})-(\d{2}) - (\d{2}):(\d{2})/) || [];

      if (!dd || !mon || !yy || !hh || !mm) {
        toast.error(
          "Unable to extract date of report creation from PDF. Please check the file."
        );
        return;
      }

      // Build the date using date-fns set()
      const localDate = set(new Date(), {
        year: 2000 + parseInt(yy),
        month: monthToNumber(mon),
        date: parseInt(dd),
        hours: parseInt(hh),
        minutes: parseInt(mm),
        seconds: 0,
      });

      const utcDate = localDate;

      // Match all dates like 01-Ene-25 (ignore timestamps like 25-Dic-24 - 07:38)
      const dateMatches = fullText.match(/\b\d{2}-[A-Za-z]{3}-\d{2}\b/g) || [];

      // Filter only table rows (i.e. date followed by a number)
      const filteredDates = dateMatches.filter((d, i, arr) => {
        const isLikelyTableRow = new RegExp(`${d}\\s+\\d+`).test(fullText);
        return isLikelyTableRow && arr.indexOf(d) === i;
      });

      if (filteredDates.length === 0) {
        toast.error("No valid table dates found in the PDF.");
        return;
      }

      // Convert dd-MMM-yy → Date → yyyy-MM-dd string
      const formatToISO = (rawDate) => {
        const [dd, mon, yy] = rawDate.split("-");
        const dateObj = new Date(
          2000 + parseInt(yy),
          monthToNumber(mon),
          parseInt(dd)
        );
        const yyyy = dateObj.getFullYear();
        const mm = String(dateObj.getMonth() + 1).padStart(2, "0"); // add 1 because it's zero-based
        const day = String(dateObj.getDate()).padStart(2, "0");
        return `${yyyy}-${mm}-${day}`;
      };

      const firstDate = formatToISO(filteredDates[0]);
      const lastDate = formatToISO(filteredDates[filteredDates.length - 1]);

      // Check if this date and hotel combination already exists in the database
      const existingRecord = await checkDuplicateUpload(
        selectedHotel,
        utcDate,
        firstDate,
        lastDate
      );

      if (existingRecord) {
        //get the hotel name using selectedHotel
        const hotelName = userHotels.find(
          (hotel) => hotel.id === selectedHotel
        ).name;

        toast.error(
          `The data for ${hotelName} imported on ${utcDate.toDateString()} for the month of ${format(
            firstDate,
            "MMMM yyyy"
          )}  already exists.`
        );
        return;
      }

      // Extract raw candidate lines that *start with a date* (not datetime!)
      const tableLines = fullText
        .split("\n")
        .flatMap((line) => line.split(/(?=\d{2}-\w{3}-\d{2})/g))
        .map((line) => line.trim())
        .filter(
          (line) => /^\d{2}-\w{3}-\d{2}(?!\s+-)/.test(line) // exclude datetime lines like "01-Abr-22 - 06:26"
        )
        .map((line) => line.replace(/\s+/g, " "));

      const parsedRows = tableLines
        .map((line) => {
          const parts = line.split(" ");
          if (parts.length < 19) {
            // toast.warning("Skipping malformed row:", line);
            return null;
          }

          const [dateStr, ...data] = parts;

          const toNum = (val) =>
            parseFloat(val.replace(/\./g, "").replace(",", "."));
          const toInt = (val) => parseInt(val, 10);

          return {
            created_at: utcDate.toISOString(),
            hotel_id: selectedHotel,
            date: formatDate(dateStr), // Properly zero-padded and month-correct
            source: "manual_upload",
            rooms_total: toInt(data[0]),
            rooms_maintenance: toInt(data[1]),
            rooms_available: toInt(data[2]),
            rooms_occupied: toInt(data[3]),
            rooms_unbooked: toInt(data[4]),
            rooms_arrival: toInt(data[5]),
            persons_arrival: toInt(data[6]),
            rooms_stay: toInt(data[7]),
            persons_stay: toInt(data[8]),
            rooms_departure: toInt(data[9]),
            persons_departure: toInt(data[10]),
            rooms_complimentary: toInt(data[11]),
            rooms_house_use: toInt(data[12]),
            rooms_day_use: toInt(data[13]),
            occupied_percent: toNum(data[14]),
            unbooked_percent: toNum(data[15]),
            revenue_total: toNum(data[16]),
            average_daily_rate: toNum(data[17]),
          };
        })
        .filter(Boolean);

      // Insert the parsed rows into the database
      if (parsedRows.length === 0) {
        toast.error(
          "No valid data found in the PDF file. Please check the file."
        );
        return;
      }

      const insertPromise = supabase.from("hotel_facts").insert(parsedRows);

      toast.promise(insertPromise, {
        pending: `Uploading ${parsedRows.length} records...`,
        success: `Uploaded ${parsedRows.length} records successfully.`,
        error: (error) => `Failed to upload data: ${error.message}`,
      });
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  useEffect(() => {
    fetchUserHotels();
  }, [userProfile]);

  return (
    <div className={styles.main_container}>
      <h4 className={styles.page_title}>Manual Upload</h4>

      <form onSubmit={handleFileUpload}>
        <div className={styles.form_container}>
          <label htmlFor="hotel" className={styles.labels}>
            Hotel
          </label>
          <select
            required
            className={`${styles.textboxes} ${styles.last} ${styles.capitalize}`}
            name="hotel"
            id="hotel"
            value={selectedHotel}
            onChange={(e) => setSelectedHotel(e.target.value)}
          >
            <option value="">--</option>
            {userHotels

              // Filter out inactive hotels
              .filter((hotel) => hotel.active)
              .map((hotel) => (
                <option key={hotel.id} value={hotel.id}>
                  {`${hotel.code} | ${hotel.name}`}
                </option>
              ))}
          </select>

          <label htmlFor="file" className={styles.labels}>
            Select file
          </label>
          <input
            type="file"
            id="file"
            name="file"
            accept=".pdf"
            required
            className={styles.textboxes}
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          <div className={styles.fn_btns}>
            <button type="submit">Upload</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ManualUpload;
