import styles from "./NewUser.module.css";
import { useNavigate } from "react-router-dom";
import ReactSelect from "../components/ui/elements/custom/ReactSelect";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { supabase } from "../supabase/client";
import GeneratePassword from "../utils/GeneratePassword";
import SendEmail from "../utils/SendEmail";
import AccountCreated from "../emails/AccountCreated";
import { render } from "@react-email/components";
import { useUser } from "../auth/userContext";
import isPermitted from "../utils/isPermitted";
import getUserRole from "../utils/getRole";
import { IoChevronForwardSharp } from "react-icons/io5";

function NewUser() {
  const [hotels, setHotels] = useState([]);
  const [roles, setRoles] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userHotels, setUserHotels] = useState([]);
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  const { authSession, accessToken, userProfile } = useUser();

  // Function to fetch hotels associated with the user
  const fetchUserHotels = () => {
    // Fetch all active hotels that this user has access to
    if (!userProfile || userProfile.user_hotel.length === 0) return;
    setHotels(
      userProfile.user_hotel
        .slice() // Clone the array to avoid mutating the original
        .sort((a, b) => a.hotels.name.localeCompare(b.hotels.name)) // Sort the array by hotel name
        .map((uh) => ({
          id: uh.hotels.id,
          name: uh.hotels.name,
          code: uh.hotels.code,
          active: uh.hotels.active,
        }))
    );
  };

  // Fetch the list of roles from Supabase
  const fetchRoles = async () => {
    try {
      const { data, error } = await supabase
        .from("roles")
        .select("id, role")
        .order("role");
      if (error) throw new Error(error.message);

      setRoles(data);
    } catch (error) {
      toastError("Failed to retrieve roles!", error.message);
      navigate("/users");
    }
  };

  useEffect(() => {
    fetchUserHotels();
    fetchRoles();
  }, []);

  // Function to show error toast messages
  const toastError = (title, message) => {
    toast.error(
      <>
        <p>{title}</p>
        <p className={styles.info}>Reason: {message}</p>
      </>,
      { toastId: "errortoast" }
    );
  };

  // Form submission handler
  const handleUserCreation = async (e) => {
    e.preventDefault();

    // Generate a default password for the user
    const password = GeneratePassword(12, {
      lowercase: true,
      uppercase: true,
      numeric: true,
      special: true,
    });

    // Validate form fields
    if (!firstName || !lastName || !email || !userRole) {
      toast.error("First name, last name, email, and role are required.");
      return;
    }

    //Check if user is permitted
    const permitted = await isPermitted(userRole, authSession);
    if (!permitted) {
      toast.error(
        `You are not permitted to create ${await getUserRole(
          userRole
        )} accounts.`
      );

      return;
    }

    const url =
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_PRD_SERVER_URL
        : import.meta.env.VITE_DEV_SERVER_URL;

    // Function to create a user in the backend
    const createUser = async () => {
      const response = await fetch(`${url}/create-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email,
          password,
          firstName,
          lastName,
          userRole,
          userHotels:
            userHotels.length > 0
              ? userHotels.map((hotel) => hotel.value)
              : null,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error);
      }

      // Generate a token for email verification
      const signupToken = GeneratePassword(10, {
        lowercase: true,
        uppercase: true,
        numeric: true,
        special: false,
      });

      try {
        const response = await fetch(`${url}/update-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, signupToken }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error(error);
      }

      const html = render(
        <AccountCreated
          email={email}
          token={signupToken}
          firstName={firstName}
        />
      );

      // Send email after successful user creation
      await SendEmail({
        to: email,
        subject: "You've been invited!",

        html: html,
      });

      return result;
    };

    // Show a toast message while creating the user and sending the email
    toast.promise(createUser(), {
      pending: {
        render() {
          return (
            <>
              Creating account for{" "}
              <span className={styles.capitalize}>
                {firstName} {lastName}
              </span>
            </>
          );
        },
      },
      success: {
        render() {
          navigate("/users");
          return (
            <>
              <p>
                <span className={styles.capitalize}>
                  {firstName} {lastName}
                </span>{" "}
                successfully created!
              </p>
              <p className={styles.info}>
                Notification sent to{" "}
                <span className={styles.lowercase}>{email}</span>
              </p>
            </>
          );
        },
      },
      error: {
        render({ data }) {
          return (
            <>
              <p>
                Account creation for{" "}
                <span className={styles.capitalize}>
                  {firstName} {lastName}
                </span>{" "}
                failed!
              </p>
              <p className={styles.info}>Reason: {data.message}</p>
            </>
          );
        },
      },
    });
  };

  return (
    <>
      <div className={styles.main_container}>
        <h4 className={styles.page_title}>
          Users <IoChevronForwardSharp /> New
        </h4>

        <form onSubmit={handleUserCreation}>
          <div className={styles.form_container}>
            <label htmlFor="firstname" className={styles.labels}>
              First name
            </label>
            <input
              required
              type="text"
              placeholder="John"
              id="firstname"
              name="firstname"
              className={styles.textboxes}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label htmlFor="lastname" className={styles.labels}>
              Last name
            </label>
            <input
              required
              type="text"
              placeholder="Doe"
              id="lastname"
              name="lastname"
              className={styles.textboxes}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <label htmlFor="email" className={styles.labels}>
              Email
            </label>
            <input
              required
              type="email"
              placeholder="johndoe@email.com"
              id="email"
              name="email"
              className={styles.textboxes}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ReactSelect
              marginBottom="32px"
              options={hotels.map((hotel) => ({
                value: hotel.id,
                label: `${hotel.code} | ${hotel.name}`,
              }))}
              label="Hotels"
              placeholder="Select hotels this user will be associated with"
              noOptionsMsg="No hotels available"
              value={userHotels}
              defaultValue={userHotels}
              onChange={setUserHotels}
            />
            <label htmlFor="role" className={styles.labels}>
              Role
            </label>
            <select
              required
              className={`${styles.textboxes} ${styles.last} ${styles.capitalize}`}
              name="role"
              id="role"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            >
              <option value="">--</option>
              {roles.map(
                (role) =>
                  role.role != userProfile.role_id.role && (
                    <option value={role.id} key={role.id}>
                      {role.role}
                    </option>
                  )
              )}
            </select>
            <div className={styles.fn_btns}>
              <button
                onClick={() => navigate("/users")}
                title="Return to user list"
                className="neutral_btn"
              >
                Cancel
              </button>
              <button type="submit">Create user</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewUser;
