import styles from "./CompetitiveInsights.module.css";
import TimelineChart from "../components/ui/elements/custom/TimelineChart";
import { useUser } from "../auth/userContext";
import { useEffect, useState, useCallback } from "react";
import {
  format,
  parseISO,
  startOfMonth,
  endOfMonth,
  formatDistanceToNow,
  min,
  max,
  lastDayOfMonth,
} from "date-fns";
import { supabase } from "../supabase/client";
import { toast } from "react-toastify";

import {
  HiMiniBackward,
  HiMiniForward,
  HiMiniPlay,
  HiMiniPause,
} from "react-icons/hi2";
import Scorecard from "../components/ui/elements/custom/Scorecard";

// UTILITY FUNCTIONS

// Utility functions for safe calculations
const divide = (numerator, denominator) => {
  if (denominator === 0 || !denominator) return 0;
  return numerator / denominator;
};

const mean = (numbers) => {
  return sum(numbers) / numbers.length; // Divide by the number of elements
};

const sum = (numbers) => {
  return numbers.reduce((acc, num) => acc + num, 0);
};

const max_num = (numbers) => {
  return numbers.reduce((acc, num) => (num > acc ? num : acc), -Infinity);
};

// Filter facts by created date
const filteredFacts = (facts, date) => {
  if (!facts || facts.length === 0) return [];

  return facts.filter(
    (f) => format(parseISO(f.created_at), "yyyy-MM-dd") === date
  );
};

function CompetitiveInsights() {
  const { userProfile } = useUser();
  const [userHotels, setUserHotels] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");

  const [compSetFacts, setCompSetFacts] = useState([]);
  const [hotelFacts, setHotelFacts] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [scoreCardsData, setScoreCardsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [minMonth, setMinMonth] = useState("");
  const [maxMonth, setMaxMonth] = useState("");
  const [sliderDateRange, setSliderDateRange] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState(null);
  const [selectedSliderIndex, setSelectedSliderIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // New states
  const [compSetMode, setCompSetMode] = useState("all");

  // DATA FETCHING FUNCTIONS

  const fetchMinMaxMonths = async (hotel) => {
    // populate the month control
    try {
      const { data: dateRange, error: dateRangeError } = await supabase
        .from("hotel_facts")
        .select("date")
        .eq("hotel_id", hotel.id)
        .order("date", { ascending: true });

      if (dateRangeError)
        throw new Error("Failed to fetch date range" + dateRangeError.message);

      const minDate = min(dateRange.map((d) => parseISO(d.date)));
      const maxDate = max(dateRange.map((d) => parseISO(d.date)));

      setMinMonth(format(minDate, "yyyy-MM"));
      // setMaxMonth(format(new Date(), "yyyy-MM"));
      setMaxMonth(format(maxDate, "yyyy-MM"));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchUserHotels = () => {
    // Fetch all active hotels that this user has access to
    if (!userProfile || userProfile.user_hotel.length === 0) return;
    setUserHotels(
      userProfile.user_hotel
        .slice() // Clone the array to avoid mutating the original
        .sort((a, b) => a.hotels.name.localeCompare(b.hotels.name)) // Sort the array by hotel name
        .map((uh) => ({
          id: uh.hotels.id,
          name: uh.hotels.name,
          active: uh.hotels.active,
        }))
    );
  };

  // Fetch id, name, locality, property type, and region of the selected hotel. Locality and property type are foreign keys to the cities and property_types tables, respectively.
  const fetchHotelDetails = async (hotelId) => {
    try {
      const { data, error } = await supabase
        .from("hotels")
        .select(
          "id, name, localities(id, name, countries(id, name)), property_types(id, name)"
        )
        .eq("id", hotelId);

      if (error)
        throw new Error("Failed to fetch hotel details: " + error.message);

      return data[0];
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchHotelFacts = async (hotel, month) => {
    // Fetch hotel facts for the selected hotel

    try {
      const { data, error } = await supabase
        .from("hotel_facts")
        .select(
          "created_at, date, rooms_total, rooms_occupied, revenue_total, average_daily_rate"
        )
        .eq("hotel_id", hotel.id)
        .gte("date", format(startOfMonth(month), "yyyy-MM-dd"))
        .lte("date", format(endOfMonth(month), "yyyy-MM-dd"))
        .order("date");

      if (error)
        throw new Error("Failed to fetch hotel facts: " + error.message);

      if (!data.length) {
        toast.info("No data available for the selected month");

        setHotelFacts([]); // Clear the existing data
        setSliderDateRange([]); // Clear the slider date range
        setLatestUpdateTime(null); // Clear the latest update
        setChartData([]); // Clear the chart data
        setScoreCardsData([]); // Clear the scorecards data

        return;
      }

      setHotelFacts(data);

      // Get the created_at dates for the fetched data
      const createdDates = data
        .map((d) => format(d.created_at, "yyyy-MM-dd"))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      setSliderDateRange(createdDates);

      // Set the latest update time
      const latestUpdate = max(data.map((d) => d.created_at));
      setLatestUpdateTime(latestUpdate);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchCompetitiveSetFacts = async () => {
    // Fetch competitive set facts for the selected competitive set
    if (!compSetMode || !selectedMonth || !selectedHotel) return;

    try {
      // Grab the list of qualifying hotel IDs based on the competitive set mode
      let compSetHotelIds = [];

      if (compSetMode === "all") {
        // Include
        // 1. All assets with the same property type as selected asset, except the selected asset

        // fetch hotels with the same property type, except the selected hotel, and are active
        const { data, error } = await supabase
          .from("hotels")
          .select("id")
          .eq("property_type_id", selectedHotel.property_types.id)
          .eq("active", true)
          .neq("id", selectedHotel.id);

        if (error)
          throw new Error(
            "Failed to fetch hotels in the same category: " + error.message
          );

        compSetHotelIds = data.map((h) => h.id);
      } else if (compSetMode === "locality") {
        // Include
        // 1. All hotels in same locality as the selected hotel, except the selected hotel, and are active

        // fetch hotels in the same locality, except the selected hotel
        const { data, error } = await supabase
          .from("hotels")
          .select("id")
          .eq("active", true)
          .eq("locality_id", selectedHotel.localities.id)
          .neq("id", selectedHotel.id);

        if (error)
          throw new Error(
            "Failed to fetch hotels in same locaility: " + error.message
          );

        compSetHotelIds = data.map((h) => h.id);
      } else if (compSetMode === "country") {
        // Include
        // 1. All hotels in same country as the selected hotel, except the selected hotel, and are active

        // fetch localites in the same country
        const { data: localities, error: localitiesError } = await supabase
          .from("localities")
          .select("id")
          .eq("country_id", selectedHotel.localities?.countries.id);

        if (localitiesError)
          throw new Error(
            "Failed to fetch localities in same country: " +
              localitiesError.message
          );

        const localitiesIds = localities.map((l) => l.id);

        // fetch hotels in the same country, except the selected hotel
        const { data, error } = await supabase
          .from("hotels")
          .select("id")
          .eq("active", true)
          .in("locality_id", localitiesIds)
          .neq("id", selectedHotel.id);

        if (error)
          throw new Error(
            "Failed to fetch hotels in same country: " + error.message
          );

        compSetHotelIds = data.map((h) => h.id);
      } else if (compSetMode === "custom") {
        //TODO: Implement custom competitive set
        // Include
        // 1. All hotels in user-defined competitive set, except the selected hotel, and are active
      }

      // Finish the query
      if (compSetHotelIds.length === 0) return;

      const { data, error } = await supabase
        .from("hotel_facts")
        .select(
          "created_at, date, rooms_total, rooms_occupied, revenue_total, average_daily_rate"
        )
        .in("hotel_id", compSetHotelIds)
        .gte("date", format(startOfMonth(selectedMonth), "yyyy-MM-dd"))
        .lte("date", format(endOfMonth(selectedMonth), "yyyy-MM-dd"))
        .order("date");

      if (error)
        throw new Error(
          "Failed to fetch competitive set facts: " + error.message
        );

      setCompSetFacts(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // DATA AGGREGATION FUNCTIONS
  const aggregateFacts = (facts) => {
    if (!Array.isArray(facts)) {
      throw new Error("Invalid input: facts must be an array");
    }

    // Aggregate facts by date and created_at, and compute occupancy, ADR, RevPAR, and total revenue for each combination of date and created_at
    const aggregated = facts.reduce((acc, curr) => {
      // Calculate occupancy as the average of sum(rooms_occupied) / sum(rooms_total)
      const occupancy = divide(curr.rooms_occupied, curr.rooms_total) * 100;

      // Calculate ADR (Average Daily Rate) as the average of sum(revenue_total) / sum(rooms_occupied)
      const ADR = curr.average_daily_rate;

      // Calculate RevPAR (Revenue Per Available Room) as sum(revenue_total) / sum(rooms_total)
      const revPAR = divide(curr.revenue_total, curr.rooms_total);

      // Calculate total revenue as the sum of revenue_total
      const totalRevenue = curr.revenue_total;

      // Format the date and created_at fields to "yyyy-MM-dd"
      const date = format(parseISO(curr.date), "yyyy-MM-dd");
      // const createdAt = format(parseISO(curr.created_at), "yyyy-MM-dd");

      // Initialize the accumulator for the date if it doesn't exist
      if (!acc[date]) {
        acc[date] = {
          occupancy: [],
          ADR: [],
          revPAR: [],
          totalRevenue: [],
        };
      }

      // Push the calculated metrics into the respective arrays for date
      acc[date].occupancy.push(occupancy);
      acc[date].ADR.push(ADR);
      acc[date].revPAR.push(revPAR);
      acc[date].totalRevenue.push(totalRevenue);

      return acc;
    }, {});

    // Calculate averages for each metric in the aggregated data
    Object.keys(aggregated).forEach((key) => {
      aggregated[key].occupancy = mean(aggregated[key].occupancy);
      aggregated[key].ADR = mean(aggregated[key].ADR);
      aggregated[key].revPAR = mean(aggregated[key].revPAR);
      aggregated[key].totalRevenue = mean(aggregated[key].totalRevenue);
    });

    return aggregated;
  };

  const mergeData = (aggregatedHotelFacts, aggregatedCompSetFacts) => {
    const mergedData = Object.keys(aggregatedHotelFacts)
      .map((key) => {
        return {
          date: parseISO(key),
          occupancy: aggregatedHotelFacts[key]?.occupancy,
          prevOccupancy: aggregatedCompSetFacts[key]?.occupancy,
          ADR: aggregatedHotelFacts[key]?.ADR,
          prevADR: aggregatedCompSetFacts[key]?.ADR,
          revPAR: aggregatedHotelFacts[key]?.revPAR,
          prevRevPAR: aggregatedCompSetFacts[key]?.revPAR,
          totalRevenue: aggregatedHotelFacts[key]?.totalRevenue,
          prevTotalRevenue: aggregatedCompSetFacts[key]?.totalRevenue,
        };
      })
      .sort((a, b) => a.date - b.date);

    return mergedData;
  };

  // HANDLERS
  const handleHotelChange = async (e) => {
    // reset compSetMode to all
    setCompSetMode("all");
    // Fetch hotel details when selected hotel changes
    setSelectedHotel(await fetchHotelDetails(e.target.value));
  };

  const handlePrevious = () => {
    setIsPlaying(false);
    setSelectedSliderIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setIsPlaying(false);
    setSelectedSliderIndex((prevIndex) =>
      Math.min(prevIndex + 1, sliderDateRange.length - 1)
    );
  };

  const handleAutoPlay = useCallback(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setSelectedSliderIndex((prevIndex) => {
          if (prevIndex < sliderDateRange.length - 1) return prevIndex + 1;
          clearInterval(interval);
          setIsPlaying(false);
          return prevIndex;
        });
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isPlaying, sliderDateRange.length]);

  const handleMonthChange = (e) => {
    const pattern = /^\d{4}-\d{2}$/;
    if (
      e.target.value &&
      e.target.value !== "" &&
      pattern.test(e.target.value)
    ) {
      setSelectedMonth(parseISO(e.target.value));
    } else {
      toast.info(
        "Invalid date format or empty date. Please select a valid month."
      );
    }
  };

  // EFFECTS

  useEffect(() => {
    // Effect to fetch user hotels on component mount

    fetchUserHotels();
  }, [userProfile]);

  useEffect(() => {
    // Set first hotel as default selected hotel
    const fetchHotel = async () => {
      if (userHotels.length > 0) {
        setSelectedHotel(await fetchHotelDetails(userHotels[0].id));
      }
    };

    fetchHotel();
  }, [userHotels]);

  useEffect(() => {
    // Fetch hotel on first load
    const fetchHotel = async () => {
      if (selectedHotel) {
        await fetchHotelDetails(selectedHotel.id);
      }
    };

    fetchHotel();
  }, []);

  useEffect(() => {
    // Fetch hotel facts when selected hotel changes

    const facts = async () => {
      if (selectedHotel && selectedMonth) {
        await fetchMinMaxMonths(selectedHotel);
        await fetchHotelFacts(selectedHotel, selectedMonth);
      }
    };
    facts();
  }, [selectedHotel, selectedMonth]);

  useEffect(() => {
    // Set the slider to the latest created_at date
    setSelectedSliderIndex(sliderDateRange.length - 1);
  }, [sliderDateRange]);

  useEffect(() => {
    // Fetch competitive set facts when selected competitive set changes
    const compFacts = async () => {
      // if (selectedCompetitiveSet.length > 0 && selectedMonth) {
      await fetchCompetitiveSetFacts();
      // }
    };
    compFacts();
  }, [compSetMode, selectedMonth, selectedHotel]);

  useEffect(() => {
    // Aggregate hotel facts

    const selectedDate = sliderDateRange[selectedSliderIndex];

    const aggregatedHotelFacts = aggregateFacts(
      filteredFacts(hotelFacts, selectedDate)
    );

    const aggregatedCompSetFacts = aggregateFacts(
      filteredFacts(compSetFacts, selectedDate)
    );

    // Merge aggregated hotel facts and competitive set facts into a single data array
    const data = mergeData(aggregatedHotelFacts, aggregatedCompSetFacts);

    setChartData(data);
  }, [hotelFacts, compSetFacts, selectedSliderIndex]);

  useEffect(() => {
    // Calculate summary metrics for the scorecards
    if (hotelFacts.length > 0) {
      const aggregatedHotelFacts = aggregateFacts(
        filteredFacts(hotelFacts, format(latestUpdateTime, "yyyy-MM-dd"))
      );

      const aggregatedCompSetFacts = aggregateFacts(
        filteredFacts(compSetFacts, format(latestUpdateTime, "yyyy-MM-dd"))
      );

      const scoreCardsData = mergeData(
        aggregatedHotelFacts,
        aggregatedCompSetFacts
      );

      const occupancy = mean(
        scoreCardsData
          .map((r) => r.occupancy)
          .filter((value) => value !== null && value !== undefined)
      );

      const prevOccupancy =
        scoreCardsData
          .map((r) => r.prevOccupancy)
          .filter((value) => value !== null && value !== undefined).length > 0
          ? mean(
              scoreCardsData
                .map((r) => r.prevOccupancy)
                .filter((value) => value !== null && value !== undefined)
            )
          : 0;

      const ADR = mean(
        scoreCardsData
          .map((r) => r.ADR)
          .filter((value) => value !== null && value !== undefined)
      );

      const prevADR =
        scoreCardsData
          .map((r) => r.prevADR)
          .filter((value) => value !== null && value !== undefined).length > 0
          ? mean(
              scoreCardsData
                .map((r) => r.prevADR)
                .filter((value) => value !== null && value !== undefined)
            )
          : 0;

      const revPAR = mean(
        scoreCardsData
          .map((r) => r.revPAR)
          .filter((value) => value !== null && value !== undefined)
      );

      const prevRevPAR =
        scoreCardsData
          .map((r) => r.prevRevPAR)
          .filter((value) => value !== null && value !== undefined).length > 0
          ? mean(
              scoreCardsData
                .map((r) => r.prevRevPAR)
                .filter((value) => value !== null && value !== undefined)
            )
          : 0;

      const totalRevenue = sum(
        scoreCardsData
          .map((r) => r.totalRevenue)
          .filter((value) => value !== null && value !== undefined)
      );

      const prevTotalRevenue = sum(
        scoreCardsData
          .map((r) => r.prevTotalRevenue)
          .filter((value) => value !== null && value !== undefined)
      );

      //create an array of the metrics above
      const dataArray = [
        {
          name: "Occupancy",
          value: occupancy,
          prevValue: prevOccupancy,
          unit: "%",
        },
        {
          name: "ADR",
          value: ADR,
          prevValue: prevADR,
          unit: "USD",
        },
        {
          name: "RevPAR",
          value: revPAR,
          prevValue: prevRevPAR,
          unit: "USD",
        },
        {
          name: "Total Revenue",
          value: totalRevenue,
          prevValue: prevTotalRevenue,
          unit: "USD",
        },
      ];

      setScoreCardsData(dataArray);
    }
  }, [chartData]);

  // ======== current stop ===========

  useEffect(handleAutoPlay, [isPlaying, sliderDateRange.length]);

  useEffect(() => {
    if (chartData.length > 0 && scoreCardsData.length > 0) {
      setIsLoading(false);
    }
  }, [chartData, scoreCardsData]);

  return (
    <div className={styles.main_container}>
      <div className={styles.filters}>
        <span>
          <label htmlFor="month" className={styles.labels}>
            Month
          </label>

          <input
            id="month"
            name="month"
            type="month"
            min={minMonth}
            max={maxMonth}
            value={format(selectedMonth, "yyyy-MM")}
            onChange={(e) => {
              handleMonthChange(e);
              setIsLoading(true); // Set loading state when month changes
            }}
            required
            pattern="\d{4}-\d{2}"
          />
        </span>

        <span>
          <label htmlFor="hotels" className={styles.labels}>
            Hotel
          </label>

          <select
            placeholder="Select hotel"
            id="hotels"
            name="hotels"
            className={styles.textboxes}
            value={selectedHotel.id}
            onChange={(e) => {
              // Set hotel when a new one is selected
              handleHotelChange(e);
              setIsLoading(true);
            }}
          >
            {userHotels

              // Filter out inactive hotels
              .filter((hotel) => hotel.active)
              .map((hotel) => (
                <option key={hotel.id} value={hotel.id}>
                  {hotel.name}
                </option>
              ))}
          </select>
        </span>
        <span>
          <label htmlFor="compSet" className={styles.labels}>
            Compare with
          </label>
          <select
            placeholder="Select Competitive Set"
            id="compSet"
            name="compSet"
            className={styles.textboxes}
            value={compSetMode}
            onChange={(e) => {
              setCompSetMode(e.target.value);
              setIsLoading(true);
            }}
          >
            <option value="all">
              All{" "}
              {selectedHotel?.property_types?.name === "Hotel"
                ? "hotels"
                : selectedHotel?.property_types?.name === "Apartments"
                ? "apartments"
                : null}
            </option>
            <option
              value="locality"
              disabled={selectedHotel?.localities?.id ? false : true}
            >
              {selectedHotel?.property_types?.name === "Hotel"
                ? "Hotels"
                : selectedHotel?.property_types?.name === "Apartments"
                ? "Apartments"
                : "Properties"}{" "}
              in{" "}
              {selectedHotel.localities
                ? selectedHotel.localities.name
                : "this locality"}
            </option>
            <option
              value="country"
              disabled={selectedHotel.localities?.countries?.id ? false : true}
            >
              {selectedHotel?.property_types?.name === "Hotel"
                ? "Hotels"
                : selectedHotel?.property_types?.name === "Apartments"
                ? "Apartments"
                : "Properties"}{" "}
              in{" "}
              {selectedHotel.localities?.countries
                ? selectedHotel.localities.countries.name
                : "this country"}
            </option>
            <option disabled value="custom">
              User-defined competitive set
            </option>
          </select>
        </span>
      </div>
      <p className={styles.latest_update}>
        {latestUpdateTime &&
          `Last updated ${formatDistanceToNow(latestUpdateTime, {
            addSuffix: true,
          })}`}
      </p>

      <div className={styles.scorecard_grp}>
        <Scorecard
          title={scoreCardsData[0]?.name}
          value={scoreCardsData[0]?.value}
          compareValue={scoreCardsData[0]?.prevValue}
          suffix="%"
          icon="hotel"
          showValue
          compareMode="hotel"
          loading={isLoading}
        />
        <Scorecard
          title={scoreCardsData[1]?.name}
          value={scoreCardsData[1]?.value}
          compareValue={scoreCardsData[1]?.prevValue}
          prefix="$"
          icon="money"
          showValue
          compareMode="hotel"
          loading={isLoading}
        />
        <Scorecard
          title={scoreCardsData[2]?.name}
          value={scoreCardsData[2]?.value}
          compareValue={scoreCardsData[2]?.prevValue}
          prefix="$"
          icon="money"
          showValue
          compareMode="hotel"
          loading={isLoading}
        />
        <Scorecard
          title={scoreCardsData[3]?.name}
          value={scoreCardsData[3]?.value}
          compareValue={scoreCardsData[3]?.prevValue}
          prefix="$"
          icon="money"
          showValue
          compareMode="hotel"
          loading={isLoading}
        />
      </div>

      <div className={styles.timelineChartContainer}>
        <TimelineChart
          compareMode={"hotel"}
          data={chartData}
          selectedDate={
            sliderDateRange[selectedSliderIndex] &&
            parseISO(sliderDateRange[selectedSliderIndex])
          }
          targetDate={lastDayOfMonth(selectedMonth)}
          maxValues={
            // Calculate the max values for each metric

            chartData.length > 0 && {
              maxADR: max_num([
                max_num(chartData.map((r) => (r.ADR ? r.ADR : 0))),
                max_num(chartData.map((r) => (r.prevADR ? r.prevADR : 0))),
              ]),
              maxRevPAR: max_num([
                max_num(chartData.map((r) => (r.revPAR ? r.revPAR : 0))),
                max_num(
                  chartData.map((r) => (r.prevRevPAR ? r.prevRevPAR : 0))
                ),
              ]),
              maxTotalRevenue: max_num([
                max_num(
                  chartData.map((r) => (r.totalRevenue ? r.totalRevenue : 0))
                ),
                max_num(
                  chartData.map((r) =>
                    r.prevTotalRevenue ? r.prevTotalRevenue : 0
                  )
                ),
              ]),
            }
          }
          selectedHotel={selectedHotel.name}
          selectedMonth={format(selectedMonth, "MMMM yyyy")}
          loading={isLoading}
        />
        {!isLoading && (
          <>
            <input
              type="range"
              className={styles.timelineSlider}
              min={0}
              // Unique max value for the slider
              max={
                hotelFacts
                  .map((d) => format(d.created_at, "yyyy-MM-dd"))
                  .filter((value, index, self) => self.indexOf(value) === index)
                  .length - 1
              }
              value={selectedSliderIndex}
              onChange={(e) =>
                setSelectedSliderIndex(parseInt(e.target.value, 10))
              }
              title={`Currently at: ${sliderDateRange[selectedSliderIndex]}`}
            />
            <div className={styles.btnGrp}>
              <span>
                <button
                  className={`${styles.grpBtn} ${styles.backBtn}`}
                  onClick={handlePrevious}
                >
                  <HiMiniBackward />
                </button>
                <button
                  className={`${styles.grpBtn} ${styles.playBtn}`}
                  onClick={() => setIsPlaying(!isPlaying)}
                >
                  {isPlaying ? <HiMiniPause /> : <HiMiniPlay />}
                </button>
                <button
                  className={`${styles.grpBtn} ${styles.forwardBtn}`}
                  onClick={handleNext}
                >
                  <HiMiniForward />
                </button>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CompetitiveInsights;
